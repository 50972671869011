<script>

export default {
    name: "CFRubricGrid",
    props: {
        criteria: Array,
        rubricId: String,
        frameworkIdentifier: String
        // --framwork ID
    },
    model: {
        prop: 'criteria',
        event: 'update:criteria'
    },
    data() {
        return {
            lastFocusedInput: undefined
        };
    },
    watch: {
        criteria: {
            handler: function (newValue) {
                this.$emit('update:criteria', newValue)
            },
            deep: true
        }
    },
    methods: {
        checkIfIsLastFocusedInput(input) {
            if (!this.lastFocusedInput) return false
            return this.lastFocusedInput.identifier === input.identifier
        },
        removeColumn(position) {
            this.criteria.forEach(criterion => {
                const items = criterion.CFRubricCriterionLevels;
                const index = items.findIndex(item => item.position === position);

                if (this.lastFocusedInput) {
                    if (this.lastFocusedInput.position === position && !this.lastFocusedInput.hasOwnProperty('CFRubricCriterionLevels')) {
                        this.lastFocusedInput = undefined
                    }
                }
                
                if (index !== -1) {
                    items.splice(index, 1);
                    
                    // Update positions for remaining columns
                    items.forEach(item => {
                        if (item.position > position) {
                            item.position--;
                        }
                    });
                }
            });
        },
        shiftColumn(direction, startPosition) {
            this.criteria.forEach(criterion => {
                const items = criterion.CFRubricCriterionLevels
                const currentIndex = items.findIndex(item => item.position === startPosition);
                if (currentIndex === -1) return items;

                const nextIndex = direction === 'right' 
                    ? currentIndex + 1 
                    : currentIndex - 1;

                // Check bounds
                if (nextIndex < 0 || nextIndex >= items.length) return items;

                // Swap positions
                const currentPos = items[currentIndex].position;
                items[currentIndex].position = items[nextIndex].position;
                items[nextIndex].position = currentPos;
            })

        },
        removeRow(position) {
            const index = this.criteria.findIndex(item => item.position === position);

            if (this.lastFocusedInput) {
                const currentRow = this.criteria[index]
                const isCurrentFocusedInputInRow = currentRow.CFRubricCriterionLevels.find(level => level.identifier === this.lastFocusedInput.identifier)
                if (isCurrentFocusedInputInRow) {
                    this.lastFocusedInput = undefined
                }
            }

            if (index !== -1) {
                this.criteria.splice(index, 1);
                
                // Update positions for remaining rows
                this.criteria.forEach(item => {
                    if (item.position > position) {
                        item.position--;
                    }
                });
            }

           
        },
        shiftRow(direction, startPosition) {
            const items = this.criteria
            const currentIndex = items.findIndex(item => item.position === startPosition);
            if (currentIndex === -1) return items;

            const nextIndex = direction === 'down' 
                ? currentIndex + 1 
                : currentIndex - 1;

            // Check bounds
            if (nextIndex < 0 || nextIndex >= items.length) return items;

            // Swap positions
            const currentPos = items[currentIndex].position;
            items[currentIndex].position = items[nextIndex].position;
            items[nextIndex].position = currentPos;
        },
        select_branches(param) {

			let data = {
                framework_identifier: this.frameworkIdentifier
            }
			
            // need code for existing association

			let show_data = { 
				// fn called when embedded satchel is hidden
				// embed_hide_callback_fn: ()=>{ this.aligning_to_standards = false },

				// fn called continuously while embedded satchel is open; if it returns true, embedded satchel will be closed
				// hide_fn: ()=>{ return ($(vapp.course_unit_editor?.$el).is(':visible') == false) },
			}

			vapp.$refs.satchel.execute('show', show_data).then(()=>{
				vapp.$refs.satchel.execute('load_framework', data).then(()=>{
					vapp.$refs.satchel.execute('chooser', {chooser_mode: true}).then((aligned_item) => {
                        vapp.$refs.satchel.execute('hide')

                        const associationIdentifier = U.new_uuid()
                        const uri = U.case_api_url(associationIdentifier, 'CFAssociations', 'v1p1')
                        const lastModified = new Date().toISOString()
                        const associationType = param === 'criterion'
                            ? 'ext:hasRubricCriterion'
                            : 'ext:hasRubricCriterionLevel'
						
                        const newAssociation = new CFAssociation({
                            "uri": uri,
                            "identifier": associationIdentifier,
                            "lastChangeDateTime": lastModified ,
                            "originNodeURI": {
                                "title": '',
                                "identifier": aligned_item.cfitem.identifier,
                                "uri": aligned_item.cfitem.uri
                            },
                            "associationType": associationType,
                            "destinationNodeURI": {
                                "title": this.lastFocusedInput.description.substring(0, 20),
                                "identifier": this.lastFocusedInput.identifier,
                                "uri": this.lastFocusedInput.uri
                            }
                        })
                        const payload =  {
                            lsdoc_identifier: this.frameworkIdentifier,
                            CFAssociations: [newAssociation]
                        }

                        this.$store.dispatch('save_framework_data', payload).then(response => {
                            const frameworkRecord = this.$store.state.framework_records.find(record => {
                                return record.lsdoc_identifier == this.frameworkIdentifier
                            })
                            if (!frameworkRecord.cfo.rubric_associations_hash[this.itemId]) {
                                frameworkRecord.cfo.rubric_associations_hash[this.itemId] = []
                            }
                            frameworkRecord.cfo.rubric_associations_hash[this.itemId].push(newAssociation)

                            if (!frameworkRecord.cfo.rubric_associations_hash[this.lastFocusedInput.identifier]) {
                                frameworkRecord.cfo.rubric_associations_hash[this.lastFocusedInput.identifier] = []
                            }
                            frameworkRecord.cfo.rubric_associations_hash[this.lastFocusedInput.identifier].push(newAssociation)
                            this.$forceUpdate()
                        });
					})
				})
			}).catch(()=>console.log('catch of vapp.$refs.satchel.execute(\'show\')'))	// this will execute when the standards are hidden
		},
        removeAssociation() {
            const frameworkRecord = this.$store.state.framework_records.find(record => {
                return record.lsdoc_identifier == this.frameworkIdentifier
            })

            const association = frameworkRecord.cfo.rubric_associations_hash[this.lastFocusedInput.identifier][0]

            this.$store.dispatch('delete_associations', {framework_record: frameworkRecord, associations_to_delete: [association]})
            if (frameworkRecord.cfo.rubric_associations_hash[this.itemId]) {
                frameworkRecord.cfo.rubric_associations_hash[this.itemId] = []
            }

            if (frameworkRecord.cfo.rubric_associations_hash[this.lastFocusedInput.identifier]) {
                frameworkRecord.cfo.rubric_associations_hash[this.lastFocusedInput.identifier] = []
            }
            this.$forceUpdate()
        },
        setLastFocusedInput: function(input) {
            this.lastFocusedInput = input
        },
        addCriterion: function() {
            const criterionIdentifier = U.new_uuid()
            const criterionLevels = this.createCriterionLevels(criterionIdentifier)
            const uri = U.case_api_url(criterionIdentifier, 'CFRubrics', 'v1p1')
            const newCriterion = new CFRubricCriterion({
                identifier: criterionIdentifier,
                uri: uri,
                description: '',
                notes: '',
                position: this.criteria.length + 1,
                rubricId: this.rubricId,
                CFRubricCriterionLevels: criterionLevels,
                lastChangeDateTime: Date.now()
            })

            this.criteria.push(newCriterion)
        },
        createCriterionLevels: function(parentId) {
            const criterionLevels = []
            for (let i = 0; i < this.criterionLevelCount; i++) {
                const criterionLevelIdentifier = U.new_uuid()
                const quality = this.criteria[0].CFRubricCriterionLevels.find(level => level.position == i+1).quality
                const uri = U.case_api_url(criterionLevelIdentifier, 'CFRubrics', 'v1p1')
                const newCriterionLevel = new CFRubricCriterionLevel({
                    identifier: criterionLevelIdentifier,
                    uri: uri,
                    description: '',
                    notes: '',
                    quality,
                    score: 1,
                    feedback: "",
                    position: i + 1,
                    rubricCriterionId: parentId,
                    lastChangeDateTime: Date.now()
                })
                criterionLevels.push(newCriterionLevel)
            }
            return criterionLevels
        },
        addCriterionLevels: function() {
            const newPosition = this.criterionLevelCount + 1
            const newQuality = this.criterionLevelCount + 1
            this.criteria.forEach(criterion => {
                const criterionLevelIdentifier = U.new_uuid()
                const uri = U.case_api_url(criterionLevelIdentifier, 'CFRubrics', 'v1p1')
                const newCriterionLevel = new CFRubricCriterionLevel({
                    identifier: criterionLevelIdentifier,
                    uri: uri,
                    description: '',
                    notes: '',
                    quality: newQuality,
                    score: 1,
                    feedback: "",
                    position: newPosition,
                    rubricCriterionId: criterion.identifier,
                    lastChangeDateTime: Date.now()
                })
                criterion.CFRubricCriterionLevels.push(newCriterionLevel)
            })
        },
        onCriterionLevelQualityChange(event, position) {
            this.criteria.forEach(criterion => {
                criterion.CFRubricCriterionLevels.forEach(level => {
                        if (level.position === position) {
                            level.quality = event.target.value
                        }
                })
            })
        }
    },
    computed: {
        framework_color_bind: function() {
            let backgroundClass;
            let borderClass;
            const lsdoc_identifier = this.frameworkIdentifier

            const frameworkColor = U.framework_color(lsdoc_identifier)

            if (!isNaN(frameworkColor)) {
                backgroundClass = `k-framework-color-${frameworkColor}-lighten-4`
                borderClass = `k-framework-color-${frameworkColor}-border`
                
                return {
                    class: [backgroundClass, borderClass].join(" ")
                }
            } else {
                const frameworkColorObject = U.framework_color_object(lsdoc_identifier, 'lighten-5')

                return {
                    style: `background-color: ${frameworkColorObject['background-color']} !important;`
                }
            }
    
		},
        framework_color_bind_button: function() {
            const lsdoc_identifier = this.frameworkIdentifier

            const frameworkColor = U.framework_color(lsdoc_identifier)

            if (!isNaN(frameworkColor)) {
                const backgroundClass = `k-framework-color-${frameworkColor}-dark`
                
                return {
                    class: backgroundClass,
                    style: {'color': 'white'}
                }
            } else {
                const frameworkColorObject = U.framework_color_object(lsdoc_identifier)
                return {
                    style: frameworkColorObject
                }
                    
            }
		},
        itemsAssociation: function () {
            return (identifier) => {
                const frameworkRecord = this.$store.state.framework_records.find(record => {
                    return record.lsdoc_identifier == this.frameworkIdentifier
                })
                const existingRubricAssociations = frameworkRecord.cfo.rubric_associations_hash[identifier];
                const association = !!existingRubricAssociations
                    ? existingRubricAssociations[0]
                    : null
                
                if (association) {
                    const items = frameworkRecord.json.CFItems
                    const item = items.find(item => item.identifier === association.originNodeURI.identifier)
                    return item.fullStatement
                }
                return null
            }
        },
        criterionLevelCount: function () {
            return this.criteria[0].CFRubricCriterionLevels.length
        },
        sortedCriteria: function () {
            const sortByPosition = (arr) => arr.sort((a, b) => a.position - b.position);
            this.criteria.forEach(criterion => {
                sortByPosition(criterion.CFRubricCriterionLevels)
            })
            const sortedCriteria = sortByPosition(this.criteria)
            return sortedCriteria
        },
    }
}
</script>

<template>
    <div id="rubric-viewer">
        <div class="grid-container">
            <div class="box">
                <div v-if="criteria.length" class=rubric-outer>
                    <div class="rubric-header">
                        <div class="rubric-header-cell" />
                        <div
                            class="rubric-header-cell"
                            v-for="criterionLevel, index in criteria[0].CFRubricCriterionLevels"
                            :key="criterionLevel.identifier"
                            style="position: relative;"
                        >   
                            <div style="position: absolute; top: -50%; left:50%; transform: translate(-50%, -50%); width: fit-content;">
                                <v-tooltip top>
                                    <template v-slot:activator="{on}">
                                        <v-icon class="action-icon" v-on="on" v-show="criterionLevel.position -1 !== 0" small @click="shiftColumn('left', criterionLevel.position)">
                                            fa fa-chevron-circle-left
                                        </v-icon>
                                    </template>
                                    Move Left
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{on}">
                                        <v-icon 
                                            class="action-icon"
                                            v-on="on" 
                                            v-show="criterionLevel.position !== criteria[0].CFRubricCriterionLevels.length" 
                                            small
                                            @click="shiftColumn('right', criterionLevel.position)"
                                        >
                                            fas fa-chevron-circle-right
                                        </v-icon>
                                    </template>
                                    Move Right
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{on}">
                                        <v-icon  class="action-icon" v-on="on" small color="red" @click="removeColumn(criterionLevel.position)">
                                            fas fa-trash-alt
                                        </v-icon>
                                    </template>
                                    Delete Column
                                </v-tooltip>
                            </div>
                            <input
                                :value="criterionLevel.quality"
                                @input="(event) => onCriterionLevelQualityChange(event, criterionLevel.position)"
                                class="quality-input"
                            />
                        </div>
                    </div>
                    <div v-for="criterion, index in sortedCriteria" :key="criterion.identifier" class="rubric-row">
                        <div style="position: absolute; top: 50%; left:-16px; transform: translate(-50%, -50%); width: fit-content; display: flex; flex-direction: column; gap:4px">
                            <v-tooltip right>
                                    <template v-slot:activator="{on}">    
                                        <v-icon  class="action-icon" v-on="on" v-show="index !== 0" small @click="shiftRow('up', criterion.position)">
                                            fas fa-chevron-circle-up
                                        </v-icon>
                                    </template>
                                    Move Up
                                </v-tooltip>
                            <v-tooltip right>
                                <template v-slot:activator="{on}"> 
                                    <v-icon  class="action-icon" v-on="on" v-show="index+1 !== criteria.length" small @click="shiftRow('down', criterion.position)">
                                        fas fa-chevron-circle-down
                                    </v-icon>
                                </template>
                                Move Down
                            </v-tooltip>
                            <v-tooltip right>
                                    <template v-slot:activator="{on}">
                                        <v-icon  class="action-icon" v-on="on" small color="red" @click="removeRow(criterion.position)">
                                            fas fa-trash-alt
                                        </v-icon>
                                    </template>
                                    Delete Row
                                </v-tooltip>
                        </div>
                        <div class="rubric-cell rubric-row-cell_description"  v-bind="checkIfIsLastFocusedInput(criterion) ? framework_color_bind : ''">
                            <v-textarea
                                background-color="transparent"
                                hide-details
                                outlined
                                no-resize
                                dense
                                height="100%"
                                v-model="criterion.description"
                                placeholder="Criterion description"
                                style="border-radius: 0;"
                                @focus="setLastFocusedInput(criterion)"
                            />
                        </div>
                        <div 
                            v-bind="checkIfIsLastFocusedInput(criterionLevel) ? framework_color_bind : ''"
                            class="rubric-cell" 
                            v-for="criterionLevel in criterion.CFRubricCriterionLevels" 
                            :key="criterionLevel.identifier"
                        >
                            <v-textarea
                                background-color="transparent"
                                hide-details
                                outlined
                                no-resize
                                dense
                                height="100%"
                                v-model="criterionLevel.description"
                                placeholder="Criterion level description"
                                style="border-radius: 0;height: 100%; border: none !important;"
                                @focus="setLastFocusedInput(criterionLevel)"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="vertical-bar">
                <v-icon  @click="addCriterionLevels" style="cursor: pointer;">fa fa-plus-circle</v-icon>
            </div>
            <div class="horizontal-bar">
                <v-icon @click="addCriterion" style="cursor: pointer;">fa fa-plus-circle</v-icon>
            </div>
        </div>
        <div v-if="!!lastFocusedInput">
            <div v-if="!!lastFocusedInput && lastFocusedInput.hasOwnProperty('CFRubricCriterionLevels')" style="display: flex; flex-direction: column;  gap:16px;">
                <div>
                    <v-btn v-if="!itemsAssociation(lastFocusedInput.identifier)" small @click="select_branches('criterion')" v-bind="framework_color_bind_button">
                        Associate Item to Criterion
                    </v-btn>
                    <v-btn v-else id="temp" small color="red" @click="removeAssociation" style="color: white;">
                        Remove association to 
                        "{{ itemsAssociation(lastFocusedInput.identifier) }}""
                    </v-btn>
                </div>
                <div style="display: flex; gap:16px;">
                    <div style="flex:1">
                        <div class="k-case-ie-line-label">Criterion Weight:</div>
                        <v-text-field
                            background-color="#fff"
                            outlined
                            dense
                            hide-details
                            v-model="lastFocusedInput.weight"
                            type="number"
                        />
                    </div>
                    <div style="flex:1">
                        <div class="k-case-ie-line-label">Criterion Notes:</div>
                        <v-textarea
                            background-color="#fff"
                            outlined
                            dense
                            hide-details
                            rows="1"
                            v-model="lastFocusedInput.notes"
                            placeholder=""
                            auto-grow
                            clearable
                        />
                    </div>
                </div>
            </div>
            <div v-else-if="!!lastFocusedInput" style="display: flex; gap:16px; flex-direction: column;">
                <div>
                    <v-btn v-if="!itemsAssociation(lastFocusedInput.identifier)" small @click="select_branches('criterionLevel')" v-bind="framework_color_bind_button">
                        Associate Item to Criterion Level
                    </v-btn>
                    <v-btn v-else small color="red" @click="removeAssociation" style="color: white;">
                        Remove association to
                        "{{ itemsAssociation(lastFocusedInput.identifier) }}"
                    </v-btn>
                </div>
                <div style="display: flex; gap:16px;">
                    <div style="flex:1">
                        <div class="k-case-ie-line-label">Criterion Score:</div>
                        <v-text-field
                            background-color="#fff"
                            outlined
                            dense
                            hide-details
                            v-model="lastFocusedInput.score"
                            type="number"
                        />
                    </div>
                    <div style="flex:1">
                        <div class="k-case-ie-line-label">Criterion Notes:</div>
                        <v-textarea
                            background-color="#fff"
                            outlined
                            dense
                            rows="1"
                            hide-details
                            v-model="lastFocusedInput.notes"
                            auto-grow
                            clearable
                        />
                    </div>
                    <div style="flex:1">
                        <div class="k-case-ie-line-label">Criterion Feedback:</div>
                        <v-textarea
                            background-color="#fff"
                            outlined
                            dense
                            hide-details
                            rows="1"
                            v-model="lastFocusedInput.feedback"
                            auto-grow
                            clearable
                        />
                    </div>   
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">

#rubric-viewer {
    margin-top: 16px;

    .dialog-title {
        width: 100%;       

        .k-editor-title {
            width: 100%;
            font-size: 16px;
            font-weight: 600;
        }
    }

    .action-icon {
        opacity: 0.5;

        &:hover {
            opacity: 1;
        }
    }

    .box {
        textarea {
            height: 100% !important;
            overflow: auto;
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
        }

        textarea::-webkit-scrollbar {
        display: none;
        }
    }

    

    .rubric-outer {
        border: 1px solid black;
        border-radius: 8px;
    }

    .rubric-row-cell_description {
        background-color: rgb(245, 245, 245) !important;
        font-weight: bold;
    }

    .rubric-header {
        height: 24px;
        display: flex;
    }

    .rubric-header-cell {
        flex: 1;
        text-align: center;
        /* overflow: hidden; */
    }

    .rubric-row {
        min-height: 80px;
        display: flex;
        position: relative;
    }

    .rubric-cell {
        min-height: 80px;
        flex: 1;
        background-color: white;
    }


    .v-input__slot {
        height: 100% !important;
    }

    .v-input__control {
        height: 100% !important;
    }

    .grid-container {
        display: grid;
        width: calc(100% - 16px);
        margin-left: 16px;
        grid-template-columns: 1fr 32px; /* Main area takes remaining width, right bar is 20px */
        grid-template-rows: auto 32px; /* Main row fits content, bottom bar is 20px */
        gap: 0; /* No gaps between grid items */
    }

    .box {
        grid-column: 1; /* Places in first column */
        grid-row: 1; /* Places in first row */
    }

    .vertical-bar {
        grid-column: 2; /* Places in second column */
        grid-row: 1; /* Places in first row */
        width: 32px;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }

    .horizontal-bar {
        grid-column: 1 / -1; /* Spans full width (both columns) */
        grid-row: 2; /* Places in second row */
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }

    .quality-input {
        border-radius: 0;
        height: 100%; 
        width: 100%;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        border-left: 1px solid gray;
    }

    .quality-input:focus {
        outline: none;
    }
}

</style>