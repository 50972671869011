<script>
import CFRubricEditorForm from './CFRubricEditorForm.vue'

const DEFAULT_NEW_RUBRIC_CRITERION_LEVEL_COUNT = 4

export default {
    name: "CFRubricEditorModal",
    components: {CFRubricEditorForm},
    props: {
        /** Controls the open state of the modal */
        value: {
            type: Boolean,
            default: false
        },
        itemId: {
            required: true
        },
        frameworkRecord: {
            type: Object,
            required: true
        }
    },
    model: {
        prop: 'value',
        event: 'update:value'
    },
    data() {
        return {
            rubricId: undefined,
            isNewRubric: true,
            title: "",
            description: "",
            notes: "",
            criteria: [],
            initialState: {
                title: "",
                description: "",
                notes: "",
                criteria: [],
            },
            rules: {
                required: value => !!value || 'Field is required',
            },
        };
    },
    mounted() {
        const existingRubricAssociation = this.getExistingRubricAssociation()
        if (existingRubricAssociation) {
            this.isNewRubric = false
            this.rubricId = existingRubricAssociation.destinationNodeURI.identifier
            const rubric = this.getRubricFromAssociation(this.rubricId)
            this.title = rubric.title;
            this.description = rubric.description
            this.notes = rubric.notes
            this.criteria = rubric.CFRubricCriteria
        } else {
            this.rubricId = U.new_uuid()
            this.title = 'New Rubric';
            this.description = ''
            this.notes = ''
            this.criteria = [this.createDefaultCriterion()]
        }
        this.setInitialState()
    },
    computed: {
        framework_color_bind: function() {
            const lsdoc_identifier = this.frameworkRecord.lsdoc_identifier
            const frameworkColor = U.framework_color(lsdoc_identifier)

            if (!isNaN(frameworkColor)) {
                const editorClass = `k-framework-color-${frameworkColor}-editor`
                
                return {
                    class: [editorClass].join(" ")
                }
            } else {
                const frameworkColorObjectEditor = U.framework_color_object(lsdoc_identifier, 'editor')
                return {
                    style: frameworkColorObjectEditor
                }
            }
    
		},
        framework_color_bind_button: function() {
            const lsdoc_identifier = this.frameworkRecord.lsdoc_identifier

            const frameworkColor = U.framework_color(lsdoc_identifier)

            if (!isNaN(frameworkColor)) {
                const backgroundClass = `k-framework-color-${frameworkColor}-dark`
                
                return {
                    class: backgroundClass,
                    style: {'color': 'white'}
                }
            } else {
                const frameworkColorObject = U.framework_color_object(lsdoc_identifier)
                return {
                    style: frameworkColorObject
                }
                    
            }
		},
        titleText: function() {
            return this.isNewRubric
                ? 'Create Rubric (Beta)'
                : 'Edit Rubric (Beta)'
        },
        isStateChanged() {
            return this.title !== this.initialState.title 
                || this.description !== this.initialState.description
                || this.notes !== this.initialState.notes
                || JSON.stringify(this.criteria) !== JSON.stringify(this.initialState.criteria)
        }
    },
    methods: {
        setInitialState() {
            this.initialState = {
                title: this.title,
                description: this.description,
                notes: this.notes,
                criteria: JSON.parse(JSON.stringify(this.criteria))
            }
        },
        /**
         *  @param isOpen {boolean}  
         */
        updateOpenState(isOpen) {
            this.$emit('input', isOpen);
        },
        /**
         * @returns {Object|null}
         */
        getExistingRubricAssociation() {
            const existingRubricAssociations = this.frameworkRecord.cfo.rubric_associations_hash[this.itemId];
            return !!existingRubricAssociations
                ? existingRubricAssociations[0]
                : null
        },
        /**
         * @param rubricId {string}
         * @returns {Object}
         */
        getRubricFromAssociation(rubricId) {
            const rubrics = this.frameworkRecord.json.CFRubrics
            return JSON.parse(JSON.stringify(rubrics.find(rubric => rubric.identifier === rubricId)))
        },
        onSaveClick() {
            const rubricToSave = this.getRubricToSave()
            const association = this.createAssociation()
            const payload =  {
                lsdoc_identifier: this.frameworkRecord.lsdoc_identifier,
                CFRubrics: [rubricToSave],
                CFAssociations: association ? [association] : []
            }
            console.log('save', payload, rubricToSave, association)
            this.saveRubric(payload, rubricToSave, association);
        },
        onCancelClick() {
            this.$emit('update:value', false)
        },
        getRubricToSave() {
            const uri = U.case_api_url(this.rubricId, 'CFRubrics', 'v1p1')
            const rubricToSave = new CFRubric({
                identifier: this.rubricId,
                uri: uri,
                title: this.title,
                description: this.description,
                notes: this.notes,
                lastChangeDateTime: '*NOW*',
                CFRubricCriteria: this.criteria
            });

            return rubricToSave
        },
        saveRubric(payload, rubric, association) {
            this.$store.dispatch('save_framework_data', payload).then(response => {
                console.log('responce', response)
                const cleanRubricJson = JSON.parse(JSON.stringify(rubric))
                const rubricIndex = this.frameworkRecord.json.CFRubrics.findIndex(x=>x.identifier == rubric.identifier)
                const statefulFrameworkRecord = this.$store.state.framework_records.find(record => {
                    return record.lsdoc_identifier == this.frameworkRecord.lsdoc_identifier
                })
                if (this.isNewRubric) {
                    this.$store.commit('set', [statefulFrameworkRecord.json.CFRubrics, 'SPLICE',statefulFrameworkRecord.json.CFRubrics.length, cleanRubricJson])
                    this.frameworkRecord.cfo.rubric_associations_hash[this.itemId] = [association]
                } else {
                    this.$store.commit('set', [statefulFrameworkRecord.json.CFRubrics, 'SPLICE', rubricIndex, cleanRubricJson])
                }
                this.setInitialState()
                
            });
        },
        createAssociation() {   
            const AssociationId = U.new_uuid();
            const uri = U.case_api_url(AssociationId, 'CFAssociations', 'v1p1')
            const originURI = U.case_api_url(this.itemId, 'CFItems', 'v1p1')
            const destinationUri = U.case_api_url(this.rubricId, 'CFRubrics', 'v1p1')
            return this.isNewRubric 
                ? new CFAssociation({
                    "uri": uri,
                    "identifier": AssociationId,
                    "lastChangeDateTime": "2018-10-24T20:48:16+00:00",
                    "originNodeURI": {
                        "title": "RL.3.1",
                        "identifier": this.itemId,
                        "uri": originURI
                    },
                    "associationType": "ext:hasRubric",
                    "destinationNodeURI": {
                        "title": this.title.substring(0, 20),
                        "identifier": this.rubricId,
                        "uri": destinationUri
                    }
                })
                : null
        },
        createDefaultCriterion() {
            const newCriterionLevels = []
            const criterionIdentifier = U.new_uuid()

            for (let i = 0; i < DEFAULT_NEW_RUBRIC_CRITERION_LEVEL_COUNT; i++) {
                const criterionLevelIdentifier = U.new_uuid()
                const uri = U.case_api_url(criterionLevelIdentifier, 'CFRubrics', 'v1p1')
                const newCriterionLevel = new CFRubricCriterionLevel({
                    identifier: criterionLevelIdentifier,
                    uri: uri,
                    description: '',
                    notes: '',
                    quality: i + 1,
                    score: 1,
                    feedback: "",
                    position: i + 1,
                    rubricCriterionId: criterionIdentifier,
                    lastChangeDateTime: Date.now()
                })
                newCriterionLevels.push(newCriterionLevel)
            }

            const uri = U.case_api_url(criterionIdentifier, 'CFAssociations', 'v1p1')
            const newCriterion = new CFRubricCriterion({
                identifier: criterionIdentifier,
                uri: uri,
                description: '',
                notes: '',
                position: 1,
                rubricId: this.rubricId,
                CFRubricCriterionLevels: newCriterionLevels,
                lastChangeDateTime: Date.now()
            })

            return newCriterion
        },
        tabber(event) {
            U.tabber(event)
        },
    },
}
</script>

<template>
    <v-dialog :value="value" @input="updateOpenState" scrollable persistent>
        
        <v-card v-bind="framework_color_bind" id="dialog-card" style="display: flex; flex-direction: column; gap: 8px">
            <v-card-title class="dialog-title">
                <div class="k-editor-title d-flex">
                    <div style="width: 100%; display: flex; justify-content: space-between; align-items: center;">
                        <div>
                            <v-icon class="mr-1">fas fa-edit</v-icon>
                            {{ titleText }}
                        </div>
                        <v-card-actions style="display: flex; flex-direction: column;">
                            <div style="flex-grow: 1"></div>
                            <div style="display:flex; justify-content: end; gap:16px; width: 100%;">
                                <template v-if="isStateChanged">
                                    <v-btn small color="secondary" @click="onCancelClick">Cancel</v-btn>
                                    <v-btn small  @click="onSaveClick" v-bind="framework_color_bind_button" :disabled="!title">
                                        <span><v-icon small class="mr-2">fas fa-save</v-icon>Save Changes</span>
                                    </v-btn>
                                </template>
                                <template v-else>
                                    <v-btn small @click="onCancelClick" v-bind="framework_color_bind_button">Done Editing</v-btn>
                                </template>
                            </div>
                        </v-card-actions>
                    </div>
                    <v-spacer/>
                </div>
                
            </v-card-title>
            <v-card-text  class="dialog-content">
                <div style="display: flex; gap: 16px">
                    <div style="flex:1;">
                        <div class="k-case-ie-line-label required">Rubric Title:</div>
                        <v-text-field
                            background-color="#fff"
                            outlined
                            dense
                                hide-details="auto"
                                :rules="[rules.required]"
                            v-model="title"
                        />
                    </div>
                    <div style="flex:1">
                        <div class="k-case-ie-line-label">Rubric Description:</div>
                        <v-textarea
                            background-color="#fff"
                            outlined
                            dense
                            hide-details
                            rows="1"
                            v-model="description"
                            auto-grow
                            clearable
                        />
                    </div>
                    <div style="flex:1">
                        <div class="k-case-ie-line-label">Rubric Notes:</div>
                        <v-textarea
                            background-color="#fff"
                            outlined
                            dense
                            hide-details
                            rows="1"
                            v-model="notes"
                            auto-grow
                            clearable
                        />
                    </div>
                </div>
                <v-spacer />
                <CFRubricEditorForm v-model="criteria" :rubric-id="rubricId" :frameworkIdentifier="frameworkRecord.lsdoc_identifier"/>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<style scoped lang="scss">

#dialog-card {
    background-color: white !important;
    border: 1px solid white;

    .dialog-title {
        .k-editor-title {
            width: 100%;
        }
    }

    .dialog-content {
        .spacer {
            height: 32px;
            width: 100%;
        }
    }
}
.required::after {
    content: "*";
    color: red;
}
</style>
